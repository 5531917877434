<template>
  <div>
    <!-- <b-card no-body class="card_first2 p-2 red"> -->
    <card-call
      :calledOne="calledOne"
      @deleteCall="deleteCall"
    />
  </div>
</template>

<script>
import CardCall from "./CardCall";
import { mapState } from "vuex";

export default {
  components: {
    CardCall,
  },
  props: {
    calls: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("patient", ["calledOne"]),
  },
  methods: {
    deleteCall(mac) {
      const me = this;
      clearTimeout(me.timeout)
      me.$emit('deleteCall', mac, me.calledOne.sensor);
    },
  },
};
</script>