<template>
  <div>
    <b-card no-body class="card_statistic">
      <b-row>
        <b-col
          md="12"
        >
          <div class="header__ justify-content-center d-flex align-items-center">
            <div class="title_card_statistic">
              Estadísticas de llamados del día
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col sm="2">
          <div class="title_statistic mb-2 text-center">Promedio respuesta</div>
          <div class="box_statistic">
            10 MIN
          </div>
        </b-col>
        <b-col sm="2">
          <div class="title_statistic mb-2 text-center">Camas disponibles</div>
          <div class="box_statistic">
            {{ noPatients.length }}
          </div>
        </b-col>
        <b-col sm="2">
          <div class="title_statistic mb-2 text-center">Camas ocupadas</div>
          <div class="box_statistic">
            {{ patients.length }}
          </div>
        </b-col>
        <b-col sm="2">
          <div class="title_statistic mb-2 text-center">No atentidos</div>
          <div class="box_statistic">
            0
          </div>
        </b-col>
        <b-col sm="2">
          <div class="title_statistic mb-2 text-center">Código azules</div>
          <div class="box_statistic">
            0
          </div>
        </b-col>
        <b-col sm="2">
          <div class="title_statistic mb-2 text-center">Total llamados</div>
          <div class="box_statistic">
            0
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    patients: {
      type: Array,
      default: () => [],
    },
    noPatients: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="css" scoped>
.card_statistic {
 box-shadow: 0 0px 15px 0 rgba(53, 176, 219, 1);
 border-radius: .6rem;
}

.header__ {
  background-color: white;
  padding: 4px;
  height: 40px;
  border-radius: .6rem;
}

.title_card_statistic {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}
.title_statistic {
  font-size: 16px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.box_statistic {
  border: 1px solid #dddddd ;
  padding: 5px;
  font-size: 22px;
  text-align: center;
  border-radius: .6rem;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}
</style>