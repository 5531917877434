<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-table
            :fields="fields"
            :items="patients"
            :busy="isBusy"
            show-empty
            small
            striped
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Cargando datos...</strong>
              </div>
            </template>
            <template #empty>
              <h4 class="text-center py-2">No hay registros</h4>
            </template>
            <!-- bed -->
            <template #cell(name)="data">
              <div class="d-flex justify-content-center">
                <div class="mr-2">
                  {{ data.item.name }}
                </div>
                <div>
                  <img
                    src="@/assets/images/icontemperatura.png"
                    class="icon_temperature_"
                  />
                  {{ data.item.device ? data.item.device : '-' }}°C
                </div>
              </div>
              <div
                v-if="data.item.isVisible"
                class="d-flex align-items-center justify-content-center"
              >
                <img
                  style="width: 60px"
                  src="@/assets/images/flechaizquierda.gif"
                  alt="helpmedica"
                />
              </div>
            </template>
            <template #cell(patient)="data">
              <div
                class="d-flex align-items-center flex-column"
                v-if="data.item.patient"
              >
                <div class="mb-1">
                  {{
                    data.item.patient.first_name +
                    " " +
                    data.item.patient.last_name
                  }}
                </div>
                <div class="d-flex" v-if="data.item.patient">
                  <div
                    v-for="risk in data.item.location_patient.risks.slice(0, 3)"
                    :key="risk.id"
                    v-b-tooltip.hover.top="risk.name"
                    class="bar_rish"
                    :style="'background-color: ' + risk.color"
                  />
                </div>
              </div>
            </template>
            <template #cell(employee)="data">
              <div v-if="data.item.employee">
                {{ data.item.employee.full_name }}
              </div>
            </template>
            <template #cell(specialty)="data">
              <div v-if="data.item.employee">
                {{ data.item.employee.specialty.name }}
              </div>
            </template>
            <template #cell(round)>
              <div class="d-flex justify-content-lg-center align-items-center">
                <div class="round" />
              </div>
            </template>
            <template #cell(exams)="data">
              <div class="text-center">
                <b-progress :max="100" animated variant="info">
                  <b-progress-bar
                    :value="
                      (data.item.exams.length / data.item.examsTotal.length) *
                      100
                    "
                  >
                    <strong>{{
                      (data.item.exams.length / data.item.examsTotal.length) *
                      100
                    }}</strong>
                  </b-progress-bar>
                </b-progress>
                <span class=""
                  ><strong
                    >{{ data.item.exams.length }} /
                    {{ data.item.examsTotal.length }}</strong
                  ></span
                >
              </div>
            </template>
            <template #cell(food_delivery)="data">
              <div v-if="data.item.patient" class="text-center">
                <div v-if="isBusy" class="text-center text-primary">
                  <b-spinner class="align-middle" />
                </div>
                <div
                  v-else-if="isError"
                  class="btn_reload"
                  @click.prevent="$emit('reloadDiets')"
                >
                  <b-icon-arrow-clockwise />
                </div>
                <div v-else-if="isEmpty">
                  <b-badge variant="danger"> Sin asignar </b-badge>
                </div>
                <div v-else>
                  <div
                    v-if="data.item.orders.length > 0"
                    class="d-flex justify-content-center flex-column"
                  >
                    <div
                      v-for="(option, index) in data.item.orders"
                      :key="index"
                      :class="colorDiet(option.type, option.status)"
                      class="box_food24"
                    >
                      {{ textDiet(option.type) }}
                    </div>
                  </div>
                  <div v-else>
                    <b-badge variant="danger"> Sin asignar </b-badge>
                  </div>
                </div>
              </div>
            </template>
            <!-- <template #cell(medicine_delivery)="">
              <div class="text-center">
                <apexchart
                  id="radiar_bar"
                  :options="medicines"
                  width="135"
                  :series="series"
                />
                <span class="title_radiar2">12/14</span>
              </div>
            </template> -->
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  VBTooltip,
  BSpinner,
  BBadge,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BSpinner,
    BBadge,
    BCard,
    BProgress,
    BProgressBar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    patients: {
      type: Array,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isBusy2: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "CAMA - DE ALTA",
        },
        {
          key: "location_patient.entry_date",
          label: "INGRESO",
        },
        {
          key: "patient",
          label: "PACIENTE - RIESGO",
        },
        {
          key: "employee",
          label: "MÉDICO",
        },
        {
          key: "specialty",
          label: "ESPECIALIDAD",
        },
        {
          key: "round",
          label: "RONDA",
        },
        {
          key: "exams",
          label: "EXÁMEN",
        },
        {
          key: "food_delivery",
          label: "ALIMENTO",
        },
        // {
        //   key: "medicine_delivery",
        //   label: "MEDICAMENTO",
        // },
      ],
      exams: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#28c76f"],
      },
      medicines: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#35b0db"],
      },
      series: ["60"],
    };
  },
  methods: {
    colorDiet(type, status) {
      let color = "";

      if (type == "1" && status) {
        color = "breakfast";
      } else if (type == "2" && status) {
        color = "luch";
      } else if (type == "3" && status) {
        color = "dinner";
      }
      return color;
    },
    textDiet(type) {
      let text = "";

      if (type == "1") {
        text = "Desayuno";
      } else if (type == "2") {
        text = "Almuerzo";
      } else {
        text = "Cena";
      }
      return text;
    },
  },
};
</script>

<style lang="css">
.table > thead {
  background: #e4e2ed !important;
  color: #5b5b5b !important;
  font-family: var(--fuente-montserrat) !important;
  font-size: 16px !important;
  text-align: center !important;
}

.table > tbody {
  color: #5b5b5b !important;
  font-family: var(--fuente-montserrat) !important;
  font-size: 18px !important;
  text-align: center !important;
}

.bar_rish_riesk {
  padding: 3.5px;
  border-radius: 0.3rem;
  background-color: #7367f0;
  margin-right: 10px;
  width: 40px;
}

.round {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #b2b2b2;
  position: relative;
  top: 3px;
}

#radiar_bar {
  display: flex;
  justify-content: center;
  position: relative;
  top: -15px;
  margin-bottom: -60px;
  margin-right: -40px;
  margin-left: -40px;
}

.title_radiar1 {
  position: relative;
  top: -16px !important;
  color: #28c76f;
  font-weight: 700;
  font-size: 16px;
}

.title_radiar2 {
  position: relative;
  top: -16px !important;
  color: #35b0db;
  font-weight: 700;
  font-size: 16px;
}

.box_food24 {
  background: #b2b2b2;
  width: 100%;
  height: 22px;
  margin-bottom: 2px;
  font-size: 16px;
  color: white;
  border-radius: 0.2rem;
  text-align: center;
}

.box_food24.breakfast {
  background-color: #28c76f;
  color: white;
}

.box_food24.luch {
  background-color: #28c76f;
  color: white;
}

.box_food24.dinner {
  background-color: #28c76f;
  color: white;
}

.icon_temperature_ {
  position: relative;
  left: 2px;
  height: 25px;
}

.bar_rish {
  padding: 3.5px;
  border-radius: 0.3rem;
  background-color: #7367f0;
  margin-right: 10px;
  width: 40px;
}

.icon_arrow {
  font-size: 25px;
}
</style>
