<template>
  <div>
    <b-row>
      <b-col sm="4" v-for="(item, index) in patients" :key="index" class="mb-4">
        <b-card no-body class="card_bed">
          <div class="header_bed_card d-flex justify-content-between">
            <div>
              <div class="d-flex">
                <div class="title_header mr-3">{{ item.name }}</div>
                <div v-if="item.isVisible" class="d-flex align-items-center justify-content-center">
                  <b-icon-arrow-left-square-fill class="mr-1 icon_arrow"/>
                  <b-spinner type="grow" :variant="item.variant" class="mr-1"></b-spinner>
                  <b-spinner type="grow" :variant="item.variant"></b-spinner>
                </div>
              </div>
              <div class="d-flex" v-if="item.location_patient">
                <div
                  v-for="(risk) in item.location_patient.risks.slice(0, 3)"
                  :key="risk.id"
                  v-b-tooltip.hover.top="risk.name"
                  class="bar_rish"
                  :style="'background-color: ' + risk.color"
                />
              </div>
            </div>
            <div>
             <img src="@/assets/images/icontemperatura.png" class="icon_temperature_bed" />
              <span class="title_icon"> {{ item.device ? item.device : '-' }}°C </span>
            </div>
          </div>
          <div class="padding_">
            <div class="d-flex justify-content-between">
              <div>
                <div class="title_body">Ronda</div>
                <div
                  class="d-flex justify-content-lg-center align-items-center"
                >
                  <div class="round_bed" />
                </div>
              </div>
              <div>
                <div class="title_body">Alimento</div>
                <div v-if="item.patient" class="text-center">
                <div v-if="isBusy" class="text-center text-primary">
                  <b-spinner class="align-middle" />
                </div>
                <div
                  v-else-if="isError"
                  class="btn_reload"
                  @click.prevent="$emit('reloadDiets')"
                >
                  <b-icon-arrow-clockwise />
                </div>
                <div v-else-if="isEmpty">
                  <b-badge variant="danger"> Sin asignar </b-badge>
                </div>
                <div v-else>
                  <div
                    v-if="item.orders.length > 0"
                    class="d-flex justify-content-center flex-column"
                  >
                    <div
                      v-for="(option, index) in item.orders"
                      :key="index"
                      :class="colorDiet(option.type, option.status)"
                      class="box_food"
                    >
                      {{ textDiet(option.type) }}
                    </div>
                  </div>
                  <div v-else>
                    <b-badge variant="danger"> Sin asignar </b-badge>
                  </div>
                </div>
              </div>
              </div>
              <div>
                <div class="title_body">Exámen</div>
                <div class="text-center">
              <b-progress
                :max="100"
                animated
                variant="info"
              >
                <b-progress-bar :value="((item.exams.length / item.examsTotal.length) * 100)">
                  <strong>{{ ((item.exams.length / item.examsTotal.length) * 100) }}</strong>
                </b-progress-bar>
              </b-progress>
              <span
                class=""
              ><strong>{{ item.exams.length }} / {{ item.examsTotal.length }}</strong></span>
            </div>
              </div>
              <!-- <div>
                <div class="title_body">Medicamento</div>
                <div class="text-center">
                  <apexchart
                    id="radiar_bar_bed"
                    :options="medicines"
                    width="135"
                    :series="series"
                  />
                  <span class="title_radiar2">12/14</span>
                </div>
              </div> -->
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BBadge,
  BIconArrowLeftSquareFill,
  BSpinner,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
    BIconArrowLeftSquareFill,
    BSpinner,
    BProgress,
    BProgressBar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    patients: {
      type: Array,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isBusy2: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exams: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#28c76f"],
      },
      medicines: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#35b0db"],
      },
      series: ["60"],
    };
  },
  methods: {
    colorDiet(type, status) {
      let color = "";

      if (type == "1" && status) {
        color = "breakfast";
      } else if (type == "2" && status) {
        color = "luch";
      } else if (type == "3" && status) {
        color = "dinner";
      }
      return color;
    },
    textDiet(type) {
      let text = "";

      if (type == "1") {
        text = "Desayuno";
      } else if (type == "2") {
        text = "Almuerzo";
      } else {
        text = "Cena";
      }
      return text;
    },
  },
};
</script>

<style lang="css">
.card_bed {
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
    0 4px 15px 0 rgba(133, 132, 132, 0.15);
  border-radius: .5rem;
}

.header_bed_card {
  background-color: rgb(253, 250, 250);
  padding: 10px;
  height: 50px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  border-radius: .5rem;
}

.title_header {
  font-size: 20px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.title_icon {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.icon_temperature_bed {
  position: relative;
  left: 2px;
  height: 25px;
}

.padding_ {
  padding: 8px;
}

.bar_rish_riesk2 {
  padding: 2.5px;
  border-radius: 0.3rem;
  background-color: #7367f0;
  margin-right: 5px;
  width: 25px;
}

.title_body {
  font-size: 16px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  margin-bottom: 5px;
  text-align: center;
}

.bar_rish_riesk {
  padding: 3.5px;
  border-radius: 0.3rem;
  background-color: #7367f0;
  margin-right: 10px;
  width: 40px;
}

.round_bed {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #b2b2b2;
  position: relative;
  top: 3px;
}

#radiar_bar_bed {
  display: flex;
  justify-content: center;
  position: relative;
  top: -15px;
  margin-bottom: -60px;
  margin-right: -40px;
  margin-left: -40px;
}

.title_radiar1 {
  position: relative;
  top: -16px !important;
  color: #28c76f;
  font-weight: 700;
  font-size: 16px;
}

.title_radiar2 {
  position: relative;
  top: -16px !important;
  color: #35b0db;
  font-weight: 700;
  font-size: 16px;
}

.box_food {
  background: #b2b2b2;
  width: 100%;
  height: 15px;
  margin-bottom: 2px;
  font-size: 10px;
  color: white;
  border-radius: 0.2rem;
  text-align: center;
}

.box_food.breakfast {
  background-color: #28c76f;
  color: white;
}

.box_food.luch {
  background-color: #28c76f;
  color: white;
}

.box_food.dinner {
  background-color: #28c76f;
  color: white;
}

.bar_rish {
  padding: 3.5px;
  border-radius: 0.3rem;
  background-color: #7367f0;
  margin-right: 10px;
  width: 40px;
}
</style>
