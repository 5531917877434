<template>
  <div>
    <Header :title="title">
      <template v-slot:btn-actions>
        <b-button
          variant="outline-secondary btn_header"
          class="margin-right"
          :class="{ active: isTable }"
          @click="cardOrList('1')"
        >
          <b-icon-table />
        </b-button>
        <b-button
          variant="outline-secondary btn_header"
          class="margin-right"
          :class="{ active: isCard }"
          @click="cardOrList('2')"
        >
          <b-icon-image />
        </b-button>
      </template>
    </Header>
    <div class="mt-2 pl-2 pr-2">
      <call-component
        class="mt-4"
        v-if="allCalls.length > 0"
        @deleteCall="deleteCall"
      />
      <b-row class="mb-5" v-else>
        <b-col md="12">
          <list-patient
            v-if="isTable"
            :patients="patients"
            :isBusy="isBusy"
            :is-busy2="isBusy2"
            :is-error="isError"
            :is-empty="isEmpty"
          />
          <card-patient
            v-else
            :patients="patients"
            :isBusy="isBusy"
            :is-busy2="isBusy2"
            :is-error="isError"
            :is-empty="isEmpty"
          />
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="allCalls.length == 0">
        <b-col cols="12">
          <statistic-call :patients="patients" :noPatients="noPatients" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BIconTable, BIconImage, BButton, BRow, BCol } from "bootstrap-vue";
import Header from "@/components/header/Header";
import ListPatient from "./ListPatient";
import CardPatient from "./CardPatient";
import StatisticCall from "./StatisticCall";
import CallComponent from "./components/CallComponent";
import { optionsMqtt } from "@/config";
import mqtt from "mqtt";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { mapState, mapActions } from "vuex";
moment.locale("es");

export default {
  inject: ["patientBedRepository"],
  components: {
    Header,
    BIconTable,
    BIconImage,
    BButton,
    BRow,
    BCol,
    ListPatient,
    CardPatient,
    StatisticCall,
    CallComponent,
  },
  data() {
    const me = this;
    return {
      title: "",
      brand: me.$route.params.sede,
      area: me.$route.params.area,
      client: null,
      isTable: true,
      isCard: false,
      isBusy: false,
      isBusy2: true,
      isError: false,
      isEmpty: false,
      patients: [],
      noPatients: [],
      areaId: me.$route.params.id,
      current_day: (moment(), moment()).format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState("patient", ["allCalls"]),
  },
  methods: {
    ...mapActions("patient", ["calls"]),
    cardOrList(type) {
      const me = this;
      if (type == "1") {
        me.isTable = true;
        me.isCard = false;
      } else {
        me.isCard = true;
        me.isTable = false;
      }
    },
    startMqttClient() {
      const me = this;

      const topic = `${me.brand}/+/#`;

      const connectUrl =
        "wss://" +
        optionsMqtt.host +
        ":" +
        optionsMqtt.port +
        optionsMqtt.endpoint;

      try {
        const uuid = uuidv4();
        me.client = mqtt.connect(connectUrl, {
          ...optionsMqtt,
          clientId: uuid,
        });
      } catch (error) {
        console.error(error);
      }

      me.client.on("connect", () => {
        console.log("Connection succeeded");

        me.client.subscribe(topic, { qos: 0 }, (err) => {
          if (err) {
            console.log("Error in subscription", err);
            return;
          }

          console.log("Subscription success");
        });
      });

      me.client.on("error", (error) => {
        console.log("Connection failed", error);
      });

      me.client.on("reconnect", (error) => {
        console.log("Reconnecting", error);
      });

      me.client.on("message", (topic, message) => {
        try {
          const messageReceived = JSON.parse(message.toString());
          if (messageReceived.llamado && messageReceived.llamado !== "Alta") {
            me.patients.forEach((el) => {
              if (el.location_patient) {
                el.devices.forEach((x) => {
                  if (
                    x.device_type_id ==
                      "a92f1b6a-9067-48d8-a00e-66802b0a2af7" &&
                    x.mac == messageReceived.mac
                  ) {
                    me.calls({
                      ...messageReceived,
                      areaLoca: me.area,
                      patient: el,
                    });
                  } else if (
                    x.device_type_id ==
                      "2224c6e8-51a4-4bb1-8ac1-7bf5bdee1932" &&
                    x.mac == messageReceived.mac
                  ) {
                    me.calls({
                      ...messageReceived,
                      areaLoca: me.area,
                      patient: el,
                    });
                  } else if (
                    x.device_type_id ==
                      "e5d8d6a7-3b5a-44fb-bd92-2e0c93995f72" &&
                    x.mac == messageReceived.mac
                  ) {
                    me.calls({
                      ...messageReceived,
                      areaLoca: me.area,
                      patient: el,
                    });
                  }
                });
              }
            });
          } else if (
            messageReceived.llamado &&
            messageReceived.llamado == "Alta"
          ) {
            me.patients.forEach((el) => {
              if (el.location_patient) {
                el.devices.forEach((x) => {
                  if (
                    x.device_type_id ==
                      "a92f1b6a-9067-48d8-a00e-66802b0a2af7" &&
                    x.mac == messageReceived.mac
                  ) {
                    if (el.isVisible) {
                      el.isVisible = false;
                      me.altaFisico(el.location_patient.id);
                    } else {
                      el.isVisible = true;
                      el.variant = "warning";
                      me.altaInfo(el.location_patient.id);
                    }
                  }
                });
              }
            });
          } else {
            me.patients.forEach(el => {
              el.devices.forEach(x => {
                if (
                  x.device_type_id == '9ff27a35-7d3c-4b18-a810-4cd36173184e' &&
                  x.mac == messageReceived.mac
                ) {
                  const item = el
                  item.device = messageReceived.sensor[0].values[0].value
                }
              })
            })
          }
        } catch (error) { 
          // console.log(error)
        }
      });
    },
    async loadLocationPatient() {
      const me = this;
      me.isBusy = true;
      me.patients = [];
      me.noPatients = [];
      try {
        const res = await me.patientBedRepository.getAllLocationPatient(
          me.areaId
        );
        res.forEach((el) => {
          el.list.forEach((elem) => {
            let isVisible = false;
            let variant = null;
            if (elem.patient) {
              if (elem.location_patient.state == "2") {
                isVisible = true;
                variant = "warning";
              }
              me.patients.push({
                ...elem,
                area_id: el.area_id,
                orders: [],
                exams: [],
                examsTotal: [],
                isVisible: isVisible,
                variant: variant,
                device: null,
              });
            } else {
              me.noPatients.push(elem);
            }
          });
        });
      } catch (error) {
        me.patients = [];
      } finally {
        me.isBusy = false;
      }
      if (me.patients.length > 0) {
        me.loadExamByAreaDate();
        me.loadOrdersCurrentDay();
      }
    },
    async loadOrdersCurrentDay() {
      const me = this;
      let orderPatients = null;
      try {
        me.isBusy2 = true;
        me.isError = false;
        me.isEmpty = false;
        const { data } = await me.patientBedRepository.getAllByDate(
          me.areaId,
          me.current_day
        );
        orderPatients = data;
        if (orderPatients.length > 0) {
          orderPatients.forEach((el) => {
            me.patients.forEach((x) => {
              if (x.patient && el.patient_id == x.patient.id) {
                x.orders.push({
                  id: el.id,
                  type: el.menu.type_menu,
                  status: !!el.arrival_at,
                });
              }
            });
          });
        }

        me.patients.forEach((el) => {
          el.orders.sort((a, b) => {
            if (a.type > b.type) {
              return 1;
            }
            if (a.type < b.type) {
              return -1;
            }
            return 0;
          });
        });
      } catch (error) {
        me.isEmpty = true;
      } finally {
        me.isBusy2 = false;
      }
    },
    async loadExamByAreaDate() {
      const me = this;
      let examPatients = null;
      try {
        const { data } = await me.patientBedRepository.getAllExamByAreaDate(
          me.areaId,
          me.current_day
        );
        examPatients = data;
        if (examPatients.length > 0) {
          examPatients.forEach((el) => {
            me.patients.forEach((x) => {
              if (x.patient && el.patient_id == x.patient.id) {
                x.examsTotal.push(el);
                if (el.made_date) {
                  x.exams.push(el);
                }
              }
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async loadLocationPatientRefresh() {
      const me = this;
      me.patients = [];
      me.noPatients = [];
      try {
        const res = await me.patientBedRepository.getAllLocationPatient(
          me.areaId
        );
        res.forEach((el) => {
          el.list.forEach((elem) => {
            let isVisible = false;
            let variant = null;
            if (elem.patient) {
              if (elem.location_patient.state == "2") {
                isVisible = true;
                variant = "warning";
              }
              me.patients.push({
                ...elem,
                area_id: el.area_id,
                orders: [],
                exams: [],
                examsTotal: [],
                isVisible: isVisible,
                variant: variant,
                device: null,
              });
            } else {
              me.noPatients.push(elem);
            }
          });
        });
      } catch (error) {
        me.patients = [];
      }
      if (me.patients.length > 0) {
        me.loadExamByAreaDate();
        me.loadOrdersCurrentDay();
      }
    },
    deleteCall(mac, sensor) {
      const me = this;
      const date = new Date();
      const timestamp = moment(date).format("YYYY-MM-DD HH:mm:ss");

      const payload = {
        llamado: "Cancelar",
        empresa: me.brand,
        sede: me.brand,
        area: me.area,
        mac: mac,
        timestamp: timestamp,
        sensor: sensor,
      };

      const payload2 = JSON.stringify(payload);
      const topic = `${me.brand}/${me.area}`;

      me.client.publish(topic, payload2, { qos: 0 }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    async altaInfo(id) {
      const me = this;
      try {
        await me.patientBedRepository.altaInfo(id);
        me.loadLocationPatientRefresh();
      } catch (error) {
        // console.log(error);
      }
    },
    async altaFisico(id) {
      const me = this;
      try {
        await me.patientBedRepository.altaFisico(id);
        me.loadLocationPatientRefresh();
      } catch (error) {
        // console.log(error);
      }
    },
    fillDiet(order) {
      const me = this;
      me.isEmpty = false;
      me.patients.forEach((el) => {
        if (el.patient.id == order.patient_id) {
          el.orders.push({
            type: order.menu.type_menu,
            status: !!order.arrival_at,
          });
        }
      });
    },
    fillExam(exam) {
      const me = this;
      me.patients.forEach((el) => {
        if (el.patient.id == exam.patient_id) {
          el.examsTotal.push(exam);
          if (el.made_date) {
            el.exams.push(exam);
          }
        }
      });
    },
    fillAltaInfo(location) {
      const me = this;
      me.patients.forEach((el) => {
        if (el.patient.id == location.patient_id) {
          el.isVisible = true;
        }
      });
    },
    fillCancelAltaInfo(location) {
      const me = this;
      me.patients.forEach((el) => {
        if (el.patient.id == location.patient_id) {
          el.isVisible = false;
        }
      });
    },
    async findLocationPatient(id) {
      const me = this;
      try {
        await me.patientBedRepository.find(id);
      } catch (error) {
        // console.log(error);
      }
    },
    fillAddPatient(location) {
      const me = this;
      me.patients.forEach((el) => {
        me.patients.push({
          ...location,
          orders: [],
          exams: [],
          examsTotal: [],
          isVisible: false,
          variant: null,
        });
      });
    },
    fillAlta(location) {
      const me = this;
      me.patients.forEach((el, index) => {
        if (el.id == location.location_id) {
          me.patients.splice(index, 1);
        }
      });
    },
  },
  async created() {
    const me = this;
    window.Echo.channel(`menu-order-${me.areaId}`)
      .listen(".menu.order.created", (e) => {
        me.loadLocationPatientRefresh();
      })
      .listen(".menu.order.status", (e) => {
        me.loadLocationPatientRefresh();
      });
    window.Echo.channel(`exam-patient-${me.areaId}`).listen(
      ".exam.patient.created",
      (e) => {
        const exam = JSON.parse(e.data);
        me.fillExam(exam);
      }
    );
    window.Echo.channel(`location-patient-${me.areaId}`)
      .listen(".location.patient.departure.info", (e) => {
        const location = JSON.parse(e.data);
        me.fillAltaInfo(location);
      })
      .listen(".location.patient.created", async (e) => {
        me.loadLocationPatientRefresh();
      })
      .listen(".location.patient.moved", async (e) => {
        me.loadLocationPatientRefresh();
      })
      .listen(".location.patient.cancel", (e) => {
        const location = JSON.parse(e.data);
        me.fillCancelAltaInfo(location);
      })
      .listen(".location.patient.departure", (e) => {
        const location = JSON.parse(e.data);
        me.fillAlta(location);
      });
    window.Echo.channel('change-turn').listen(
      ".turn.changed",
      (e) => {
        me.loadLocationPatientRefresh();
      }
    );
  },
  mounted() {
    const me = this;
    me.loadLocationPatient();

    me.title =
      me.brand.split("_").join(" ") + " - " + me.area.split("_").join(" ");
    me.startMqttClient();
  },
};
</script>

<style>
.active {
  background-color: #6c757d;
  color: var(--white-color);
}
</style>
